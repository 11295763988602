$primary: rgb(0, 107, 168);
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/reboot";
html {
    height: 100vh;
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    // font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
}

div#root {
    height: 100%;
    width: 100%;
    background: #efefef;
}

* {
     ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
     ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
     ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 18px;
    }
     ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
     ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
     ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
     ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 18px;
    }
     ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.burgermenu svg {
    width: 2.3rem;
    height: 2.3rem;
}

.button {
    border-radius: 4px;
    height: 36px;
    border: none;
}

.danger-button {
    background: #e74c3c;
    color: #ffffff;
    &:hover {
        background: #ee5a4a;
        color: #ffffff;
    }
}

.success-button {
    background: #66bb6a;
    color: #ffffff;
    &:hover {
        background: #76c079;
        color: #ffffff;
    }
}

select {
    max-width: 172px;
}

.form-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #263238;
}

.button-container {
    display: flex;
    button {
        margin-left: 30px;
    }
}

// .input-and-btn {
//   display: flex;
//   input {
//     max-width: 500px;
//     width: 100%;
//   }
// }
.col.search-and-btns {
    display: flex;
    button {
        margin-left: 20px;
    }
}

// login
.login--inner,
.reset-password,
.forgot-password {
    background: #ffffff;
    box-shadow: 0px 1px 4px #80808069;
    border-radius: 4px;
    h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #263238;
    }
    input.form-control {
        height: 56px;
    }
    button {
        height: 50px;
        background: #009bf5;
        border-radius: 4px;
        border: none;
    }
    a {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #009bf5;
        text-decoration: none;
    }
    .form-check-label {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #000000;
    }
    form {
        div {
            margin: 12px 0px;
        }
    }
    .assistive--text {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #a9a9a9;
    }
    .password-container {
        position: relative;
    }
    i {
        position: absolute;
        top: 20px;
        right: 15px;
        z-index: 8;
    }
}

.login-footer {
    position: relative;
    top: 80px;
    .copyright-container {
        font-size: 10px;
        line-height: 24px;
        /* identical to box height, or 240% */
        letter-spacing: 0.1px;
        text-transform: uppercase;
        color: rgba(32, 32, 32, 0.6);
    }
}

.privacy-btn_container {
    a {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #009bf5;
        text-decoration: none;
    }
}

.sideBar {
    border-right: 1px solid #ccc;
}

#dropdown-custom-components {
    background: white;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    object-fit: cover;
}

a .nav-link {
    .active {
        background-color: red;
    }
}

.nav-link {
    color: #79747e;
    .active {
        color: #009bf5;
    }
}

.sidebar-main {
    padding: 0px 0px;
}

.trackify-sidebar {
    background: #ffffff;
    box-sizing: border-box;
    height: calc(100vh - 50px);
    padding-top: 10px;
    .nav-pills a {
        height: 50px;
        font-weight: 500;
        font-size: 14px;
        /* line-height: 16px; */
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #333;
        font-family: 'Roboto';
    }
    .image {
        display: flex;
        // justify-content: center;
        align-items: center;
        justify-content: center;
        margin: 10px;
        margin-top: 20px;
    }
    .nav-pills {
        margin-left: 0px;
        .nav-link {
            border-radius: 0px;
            svg {
                height: 20px;
                width: 20px;
                min-width: 20px;
                fill: #666;
                margin-left: -6px;
            }
        }
    }
    .nav-pills .nav-link.active {
        color: #fff;
        background-color: $primary;
        svg {
            fill: #fff;
            path {
                fill-opacity: 1;
            }
        }
    }
}

.main-container,
.main-container-big {
    background: #eeeeee;
    // overflow-x: hidden;
    // overflow-y: auto;
    height: 100%;
    .tab-content {
        background: #ffffff;
        padding: 10px 20px;
    }
}

.table>thead,
.table>tbody {
    width: 100%;
}

table {
    outline: 1px solid rgba(88, 88, 88, 0.2);
}

td {
    vertical-align: middle;
}

thead tr {
    background: rgb(0, 107, 168);
    color: #fff;
    border-style: hidden;
    th {
        // font-style: normal;
        font-weight: bolder;
        // font-size: 12px;
        // line-height: 16px;
        // letter-spacing: 1.5px;
        text-transform: uppercase;
    }
}

tbody tr {
    background: white;
    td {
        font-style: normal;
        font-family: 'Roboto';
        FONT-WEIGHT: 500;
        font-size: 15px;
        color: #333;
        line-height: 22px;
        /* or 143% */
        letter-spacing: 0.25px;
        /* Clockify/Text Soft */
        // color: rgba(38, 50, 56, 0.8);
    }
}

td.action-buttons a {
    margin-left: 10px;
    svg {
        height: 20px;
        width: 20px;
    }
}

.hide-mobile {
    display: none;
}

.main-box {
    display: flex;
    flex-wrap: wrap;
}

.sidebar-main {
    // flex: 12%;
    max-width: 200px;
    background-color: #fff;
}

.sidebar-mini {
    // flex: 2%;
    max-width: 60px;
    padding: 0;
}

.sidebar {
    min-width: 20px;
}

.main-container-big {
    flex: 95%;
}

.main-container {
    flex: 88%;
}

@media only screen and (min-width: 1300px) {
    .login-footer {
        position: relative;
        top: 100px;
    }
}

#custom-switch {
    background-color: red;
    cursor: pointer;
    min-height: 20px;
    min-width: 50px;
    border: none;
    height: 20px;
}

#custom-switch:checked {
    background-color: #28a745;
}

.burgermenu:hover {
    cursor: pointer;
}

.dashboard-report-table {
    padding: 20px;
    outline: none;
    thead th {
        padding: 5px 10px 5px 10px;
    }
    td {
        padding: 10px;
    }
}

.dashboard-team-activites-table {
    thead tr {
        background: rgb(0, 107, 168);
        color: #fff;
    }
    th {
        padding: 5px 10px 5px 10px;
        font-weight: bolder;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 18px;
        p {
            margin-bottom: 2px;
        }
    }
    td {
        border-bottom: 1px solid #ccc;
        padding: 10px;
        font-size: 14px;
        line-height: 18px;
        p {
            margin-bottom: 2px;
        }
        svg.rounded-image,
        img.rounded-image {
            border: 2px solid #333;
            padding: 5px;
        }
    }
    .activity-description,
    .activity-tracker {
        border-left: 1px dotted #ccc;
        padding-left: 14px;
        min-height: 38px;
    }
    .activity-tracker {
        display: flex;
        align-items: center;
    }
}

.dashboard-team-activites-card-header {
    background: rgb(0, 107, 168);
    color: #fff;
    border-bottom: none;
}

.threedots:after {
    content: "\2807";
    font-size: 30px;
    text-decoration: none;
}

.date-input-field {
    width: 100%
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.form-group-by {
    background: rgb(0, 107, 168);
    color: #fff;
    padding: 2px;
}

.description-number {
    margin-right: 2px;
    color: #fff;
    &:hover {
        cursor: pointer;
    }
}

.rounded-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    top: 0;
    left: 0;
    border-radius: 50%;
}

.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.absolute {
    position: absolute;
}

.relative {
    position: 'relative'
}

.set-password--wrap {
    position: relative;
    margin: 0 !important;
    padding: 0;
}

.set-password--wrap svg {
    position: absolute;
    top: 28%;
    right: 10px;
    transform: translate(-50%, 0);
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none;
}

.set-password--wrap.invalid-password svg {
    top: 17%;
}

.react-datepicker .btn.btn-primary {
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    background: transparent;
    color: #000;
    border: none;
    border-radius: 0;
    outline: 0;
}

.days-wrapper {
    max-width: 240px;
    margin-left: 0;
    margin-right: 0;
}

.react-datepicker .days-wrapper button.btn.btn-primary {
    border-bottom: 1px solid #aeaeae;
    &:last-child {
        border-bottom: none !important;
    }
}

.react-datepicker__month-container {
    border-right: 1px solid #aeaeae;
    min-height: 290px;
}

.react-datepicker .btn.btn-primary.active {
    color: #fff;
    background-color: #005686;
    border-color: #00507e;
    box-shadow: none;
}

// The default keyboard-selected style will show the start date highlighted, even after the date has been changed. Overriding the class with the following style will remove the unnecessary highlight
.react-datepicker__day--keyboard-selected {
    background-color: inherit;
    color: inherit;
    &:hover {
        background-color: #f0f0f0;
    }
}

.react-datepicker__navigation--next {
    right: 250px;
}

// new add css
.sidebar-main img {
    max-width: 150px;
}

.trackify-sidebar .nav-pills a div {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    flex-wrap: nowrap;
    align-items: center;
    height: 40px;
    flex-direction: row-reverse;
    max-width: max-content;
}

.trackify-sidebar .nav-pills a div.hide-mobile {
    display: none;
}

.main-container .right-content-wrap button.btn,
.start-timer-button-wrapper button.btn.btn-primary {
    width: 100%;
}

.main-container button.btn,
.main-container-big button.btn {
    width: auto;
    height: 100%;
    font-family: 'Roboto';
    FONT-WEIGHT: 500;
    font-size: 15px;
    text-transform: uppercase;
    height: 40px;
}

.time-traker-wrap {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    .row.right-content-wrapper {
        padding-left: 0;
    }
    .form-select {
        width: 100%;
        max-width: 100%;
    }
}

.total-time--wrap {
    align-items: center;
    button {
        padding: 0;
        svg {
            min-width: 25px;
            min-height: 25px;
        }
    }
    div {
        padding: 0;
    }
}

.time-input--wrapper {
    align-items: center;
    .timer-wrap {
        text-align: center;
        font-size: 20px;
        FONT-WEIGHT: 500;
        font-family: 'Roboto';
    }
    .right-content-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .form-select {
            max-width: 100%;
        }
    }
}

nav.navbar.navbar-expand-lg.navbar-light {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
}

.total-time--wrap {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.total-update-time-wrap p,
.total-log-time-wrap span strong,
.total-week-log-time-wrap span strong {
    font-size: 20px;
    FONT-WEIGHT: 500;
    font-family: 'Roboto';
    letter-spacing: 1px;
    color: #333;
}

.total-log-time-wrap span,
.total-week-log-time-wrap span {
    font-size: 12px;
    FONT-WEIGHT: 500;
    font-family: 'Roboto';
    color: #999;
    line-height: 18px;
}

.total-log-time-wrap,
.total-week-log-time-wrap {
    align-items: center;
}

.main-container input {
    border: 1px solid #C6D2D9;
    transition: none;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    // box-shadow: 0px 0px 5px 2px #0000001a;
    height: 40px;
}

.main-container input:focus {
    // border: none;
    box-shadow: none;
}

// .main-container input:hover {
//     box-shadow: none;
//     border: 1px solid #C6D2D9;
// }
.trackify-sidebar .nav-pills .nav-link {
    position: relative;
}

.trackify-sidebar .nav-pills .nav-link:hover:before {
    width: 100%;
}

.trackify-sidebar .nav-pills .nav-link:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 50px;
    background: #E4EAEE;
    transition: width .2s ease;
    z-index: -1111;
}

.main-container {
    input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        // border: 1px solid #ced4da;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        min-height: 40px;
    }
}

select.form-control.form-select {
    width: 100%;
    max-width: 100%;
    height: 40px;
    cursor: pointer;
}

.table> :not(caption)>*>* {
    padding: 0.75rem 1rem;
}

.trackify-sidebar.sidebar {
    position: fixed;
    position: -webkit-sticky;
    top: 50px;
    z-index: 0;
}

.sidebar-main .trackify-sidebar.sidebar {
    width: 100%;
    max-width: 200px;
}

.dashboard-timer-wrapper section#group-bar {
    padding: 0 30px 30px;
}

.profile-content-wrapper .profile_label {
    FONT-WEIGHT: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    max-width: max-content;
    min-width: 80px;
    font-family: 'Roboto';
    text-transform: uppercase;
    padding-right: 0;
}

.badge {
    font-weight: 500;
    font-family: 'Roboto';
    padding: 5px;
}

.login-wrapper {
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    min-height: 400px;
}

.login.container {
    padding-top: 80px;
}

.footer-content-wrapper {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.total-update-time-wrap {
    max-width: 140px;
}

.text-right {
    text-align: right;
}

button.tag-btn-wrap.btn.btn-primary svg {
    min-width: 20px;
    min-height: 20px;
}

.time-dash-wrapper {
    align-items: center;
}

.row.time-dash-wrapper input.form-control[type="time"] {
    // min-width: 130px;
}

.react-datepicker-wrapper input {
    width: 100%;
    background-color: white;
    border: 0;
    border-width: 1px;
    border-color: #eee;
    border-style: solid;
    border-radius: 2px;
    padding: 5px;
    text-align: left;
    position: relative;
    box-sizing: border-box;
    height: 38px;
}

.dashboard-team-activites-table-wrapper {
    width: 100%;
    padding: 0;
}

table.dashboard-team-activites-table,
.team-member-table-wrapper,
.team-group-wrapper,
.dashboard-team-activites-table-wrapper {
    width: 100%;
    // cursor: grab;
    // display: block;
    // overflow-x: hidden;
}

td.time-date-wrap {
    p {
        margin: 5px 0;
    }
}

td.description--report-wrap p {
    text-indent: initial;
    margin-left: 10px;
}

td.description--report-wrap p.descriptionn {
    margin: 10px 10px 5px;
}

table.report---wrapper td {
    text-indent: 0;
}

.dashboard--button--wrapper {
    line-height: 44px;
}

@media only screen and (max-width:865px) {
    .team-member-table-wrapper table,
    .team-group-wrapper table,
    .dashboard-team-activites-table-wrapper table {
        // overflow-x: auto;
        margin-bottom: 0;
        // display: block;
    }
}

@media only screen and (min-width:1199px) {
    .timetracker--form .invalid-feedback {
        position: absolute;
        bottom: -19px;
        max-width: max-content;
    }
    .col-xl-6.col-md-12.mt-1.mb-1.timetracker--form {
        position: relative;
    }
}

@media only screen and (min-width:1300px) {
    .datepicker--wrapper {
        padding-right: 0;
    }
}

@media only screen and (max-width:1200px) {
    .dashboard-team-activites-table td {
        min-width: 100px;
    }
}

.pagination {
    padding-top: 20px;
}

.dashboard-report-table table {
    width: 100%;
}

.sidebar-main-mobile {
    visibility: hidden;
    display: flex;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background .2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding: 0;
}

.form-switch .form-check-input {
    background-position: left center;
}

.picky__dropdown input[type="checkbox"] {
    width: 25px;
    min-height: 25px;
    height: 25px;
    margin-right: 5px !important;
    position: relative;
}

.picky__dropdown .option,
.picky__dropdown li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.picky__dropdown .option.selected input[type="checkbox"]:after,
.picky__dropdown li.selected input[type="checkbox"]:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

#dropdown-custom-components {
    position: relative;
    svg.rounded-image.absolute {
        width: 30px;
        height: 30px;
        top: -12px;
        left: -11.5px;
        transform: translate(50%, 50%);
    }
}

@media only screen and (min-width: '1640px') {
    div.right-content-wrapper {
        max-width: 470px;
    }
}

@media only screen and (min-width: '1200px') {
    .time-schedule-wrap {
        padding: 0;
    }
    .time-picker-wrapper {
        // max-width: 320px;
    }
    .right-content-wrapper {
        max-width: 315px;
    }
}

@media only screen and (max-width: '1250px') {
    .dashboard-team-activites-table-wrapper {
        // width: 1000px;
    }
}

@media only screen and (max-width: "532px") {
    .react-datepicker .days-wrapper button.btn.btn-primary {
        min-height: 55px;
    }
}

@media only screen and (max-width: "767px") {
    .react-datepicker .btn.btn-primary {
        padding: 5px;
        height: auto !important;
    }
    button.change-btn-wrap {
        max-width: max-content;
        min-width: 150px;
    }
    .profile-image {
        margin-left: 0 !important;
    }
    .pie-width-wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto 20px;
    }
    div.main-container {
        flex: none;
        width: 100%;
    }
}

@media only screen and (max-width: "576px") {
    .datepicker-wrap,
    input#datepicker {
        margin-top: 10px;
    }
    .time-select-wrap {
        width: 100%;
    }
}

@media only screen and (max-width: "600px") {
    .pie-width-wrap {
        max-width: 300px;
    }
}

@media only screen and (max-width: "745px") {
    td.description--report-wrap,
    td.time-date-wrap {
        min-width: 209px;
    }
    td.description--report-wrap {
        min-width: 200px;
    }
    td.time-date-wrap {
        min-width: 220px;
    }
    .sidebar-main {
        // display: inline;
    }
    .projectlist-wrapper,
    .report--cotent--wrapper {
        width: 100%;
        cursor: grab;
        display: block;
        overflow-x: hidden;
    }
    .projectlist-wrapper table,
    .report--cotent--wrapper table {
        overflow-x: auto;
        margin-bottom: 0;
        display: block;
    }
}

@media only screen and (max-width: "992px") {
    .sidebar-main,
    .sidebar-mini {
        display: none;
    }
    div.sidebar-main-mobile.active {
        visibility: visible;
        background: rgba(0, 0, 0, .8);
        .trackify-sidebar.sidebar {
            max-width: 320px;
            width: 100%;
            padding-top: 40px;
        }
    }
    button.navbar-toggler {
        display: none;
    }
    div#navbarScroll.collapse:not(.show) {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .sidebar-main-mobile.active .trackify-sidebar .image {
        justify-content: flex-start;
        padding: 0 10px;
    }
}

.main---wrapper.container-fluid {
    padding: 0;
}

.projectlist-wrapper td.action-buttons {
    min-width: 130px;
}

td.action-buttons a:first-child {
    margin-left: 0;
}

.time-card-wrapper.card {
    background: transparent;
    .time-traker-wrap {
        background: #fff;
    }
}

.card-body .time-traker-wrap {
    margin: 0 0 14px !important;
}

.card-body .time-traker-wrap:last-child {
    margin: 0 !important;
}

.form-control.PhoneInput input {
    border: none;
}

:focus-visible {
    outline: none;
}

.progress-bar {
    min-width: 100px;
}

button.accordion-button.collapsed {
    background-color: #e6f0f6;
}

.accordion-item:last-of-type {
    margin-bottom: 10px !important;
}

button.accordion-button {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 20px;
}

.accordion-body {
    font-size: 14px;
    line-height: 18px;
}

@media only screen and (max-width: 600px) {
    .accordion-body {
        padding: 1rem 0.25rem;
        .px-4 {
            padding: 0 5px !important;
        }
    }
}

.ot-parent {
    justify-content: center;
    align-items: center;
    display: flex;
    .ot-btn {
        border-width: 2px;
        border-style: solid;
        border-radius: 100%;
        height: 40px !important;
        width: 40px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: aliceblue;
    }
    .ot-btn-list {
        border-width: 2px;
        border-style: solid;
        border-radius: 100%;
        height: 35px !important;
        width: 35px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: aliceblue;
    }
}

.btn-check:focus+.btn-light,
.btn-light:focus {
    border-color: none;
    box-shadow: none;
}

.datepicker-wrap {
    .react-datepicker__navigation--next {
        right: 0;
    }
    input.react-datepicker-ignore-onclickoutside {
        font-size: 15px;
        padding: 0 2px;
    }
}

// dashboard progress bar 
progress[value]::-webkit-progress-bar {
    background-color: #FFF;
}

progress::-moz-progress-bar {
    background: #FFF;
}

.spinner-container {
    content: '';
    background: rgba(255, 255, 255, .5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.timer-loader {
    width: 40px;
    height: 40px;
    border: 3px solid #009BF5;
    background: #d5f0ff;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 1s linear infinite;
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        background: #003380;
        width: 3px;
        height: 20px;
        transform: translateX(-50%);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.PhoneInputCountry {
    padding-left: 10px;
}

#most-tracked-items {
    .items-traker {
        border-top: 1px solid #ccc;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        padding-top: 10px;
        align-items: center;
    }
}

section.report-filter {
    button#picky__button__button {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
        color: #212529;
        cursor: pointer;
    }
    .picky__input::after {
        position: absolute;
        border-top: none;
        border-right: none;
        border-left: none;
        right: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: right 12px center;
        background-size: 16px 12px;
        appearance: none;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }
}