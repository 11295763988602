.bg-maroon-progress-bar{
  background-color: #800000 !important;
}
.bg-darkred-progress-bar{
  background-color: #8B0000 !important;
}
.bg-fire-brick-progress-bar{
  background-color: #B22222 !important;
}
.bg-red-progress-bar{
  background-color: #FF0000 !important;
}
.bg-salmon-progress-bar{
  background-color: #FA8072 !important;
}
.bg-tomato-progress-bar{
  background-color: #FF6347 !important;
}
.bg-coral-progress-bar{
  background-color: #FF7F50 !important;
}
.bg-orangered-progress-bar{
  background-color: #FF4500 !important;
}
.bg-chocolate-progress-bar{
  background-color: #D2691E !important;
}
.bg-sandy-brown-progress-bar{
  background-color: #F4A460 !important;
}
.bg-dark-orange-progress-bar{
  background-color: #FF8C00 !important;
}
.bg-orange-progress-bar{
  background-color: #FFA500 !important;
}
.bg-darkgoldenrod-progress-bar{
  background-color: #B8860B !important;
}
.bg-goldenrod-progress-bar{
  background-color: #DAA520 !important;
}
.bg-gold-progress-bar{
  background-color: #FFD700 !important;
}
.bg-olive-progress-bar{
  background-color: #808000 !important;
}
.bg-yellow-progress-bar{
  background-color: #FFFF00 !important;
}
.bg-yellow-green-progress-bar{
  background-color: #9ACD32 !important;
}
.bg-green-yellow-progress-bar{
  background-color: #ADFF2F !important;
}
.bg-chartreuse-progress-bar{
  background-color: #7FFF00 !important;
}
.bg-lawn-green-progress-bar{
  background-color: #7CFC00 !important;
}
.bg-green-progress-bar{
  background-color: #008000 !important;
}
.bg-lime-progress-bar{
  background-color: #00FF00 !important;
}
.bg-lime-green-progress-bar{
  background-color: #32CD32 !important;
}
.bg-spring-green-progress-bar{
  background-color: #00FF7F !important;
}
.bg-medium-spring-green-progress-bar{
  background-color: #00FA9A !important;
}
.bg-turquoise-progress-bar{
  background-color: #40E0D0 !important;
}
.bg-lightseagreen-progress-bar{
  background-color: #20B2AA !important;
}
.bg-mediumturquoise-progress-bar{
  background-color: #48D1CC !important;
}
.bg-dark-cyan-progress-bar{
  background-color: #008B8B !important;
}
.bg-aqua-progress-bar{
  background-color: #00FFFF !important;
}
.bg-dark-turquoise-progress-bar{
  background-color: #00CED1 !important;
}
.bg-deep-sky-blue-progress-bar{
  background-color: #00BFFF !important;
}
.bg-dodger-blue-progress-bar{
  background-color: #1E90FF !important;
}
.bg-royal-blue-progress-bar{
  background-color: #4169E1 !important;
}
.bg-navy-progress-bar{
  background-color: #000080 !important;
}
.bg-dark-blue-progress-bar{
  background-color: #00008B !important;
}
.bg-medium-blue-progress-bar{
  background-color: #0000CD !important;
}
.bg-blue-progress-bar{
  background-color: #0000FF !important;
}
.bg-blue-violet-progress-bar{
  background-color: #8A2BE2 !important;
}
.bg-dark-orchid-progress-bar{
  background-color: #9932CC !important;
}
.bg-dark-violet-progress-bar{
  background-color: #9400D3 !important;
}
.bg-purple-progress-bar{
  background-color: #800080 !important;
}
.bg-dark-magenta-progress-bar{
  background-color: #8B008B !important;
}
.bg-magenta-progress-bar{
  background-color: #FF00FF !important;
}
.bg-medium-violet-red-progress-bar{
  background-color: #C71585 !important;
}
.bg-deep-pink-progress-bar{
  background-color: #FF1493 !important;
}
.bg-hot-pink-progress-bar{
  background-color: #FF69B4 !important;
}
.bg-crimson-progress-bar{
  background-color: #DC143C !important;
}
.bg-brown-progress-bar{
  background-color: #A52A2A !important;
}
.bg-indian-red-progress-bar{
  background-color: #CD5C5C !important;
}
.bg-rosy-brown-progress-bar{
  background-color: #BC8F8F !important;
}
.bg-light-coral-progress-bar{
  background-color: #F08080 !important;
}
.bg-snow-progress-bar{
  background-color: #FFFAFA !important;
}
.bg-misty-rose-progress-bar{
  background-color: #FFE4E1 !important;
}
.bg-dark-salmon-progress-bar{
  background-color: #E9967A !important;
}
.bg-light-salmon-progress-bar{
  background-color: #FFA07A !important;
}
.bg-sienna-progress-bar{
  background-color: #A0522D !important;
}
.bg-sea-shell-progress-bar{
  background-color: #FFF5EE !important;
}
.bg-saddle-brown-progress-bar{
  background-color: #8B4513 !important;
}
.bg-peachpuff-progress-bar{
  background-color: #FFDAB9 !important;
}
.bg-peru-progress-bar{
  background-color: #CD853F !important;
}
.bg-linen-progress-bar{
  background-color: #FAF0E6 !important;
}
.bg-bisque-progress-bar{
  background-color: #FFE4C4 !important;
}
.bg-burlywood-progress-bar{
  background-color: #DEB887 !important;
}
.bg-tan-progress-bar{
  background-color: #D2B48C !important;
}
.bg-antique-white-progress-bar{
  background-color: #FAEBD7 !important;
}
.bg-navajo-white-progress-bar{
  background-color: #FFDEAD !important;
}
.bg-blanched-almond-progress-bar{
  background-color: #FFEBCD !important;
}
.bg-papaya-whip-progress-bar{
  background-color: #FFEFD5 !important;
}
.bg-moccasin-progress-bar{
  background-color: #FFE4B5 !important;
}
.bg-wheat-progress-bar{
  background-color: #F5DEB3 !important;
}
.bg-oldlace-progress-bar{
  background-color: #FDF5E6 !important;
}
.bg-floralwhite-progress-bar{
  background-color: #FFFAF0 !important;
}
.bg-cornsilk-progress-bar{
  background-color: #FFF8DC !important;
}
.bg-khaki-progress-bar{
  background-color: #F0E68C !important;
}
.bg-lemon-chiffon-progress-bar{
  background-color: #FFFACD !important;
}
.bg-pale-goldenrod-progress-bar{
  background-color: #EEE8AA !important;
}
.bg-dark-khaki-progress-bar{
  background-color: #BDB76B !important;
}
.bg-beige-progress-bar{
  background-color: #F5F5DC !important;
}
.bg-light-goldenrod-yellow-progress-bar{
  background-color: #FAFAD2 !important;
}
.bg-light-yellow-progress-bar{
  background-color: #FFFFE0 !important;
}
.bg-ivory-progress-bar{
  background-color: #FFFFF0 !important;
}
.bg-olive-drab-progress-bar{
  background-color: #6B8E23 !important;
}
.bg-dark-olive-green-progress-bar{
  background-color: #556B2F !important;
}
.bg-dark-sea-green-progress-bar{
  background-color: #8FBC8F !important;
}
.bg-darkgreen-progress-bar{
  background-color: #006400 !important;
}
.bg-forestgreen-progress-bar{
  background-color: #228B22 !important;
}
.bg-light-green-progress-bar{
  background-color: #90EE90 !important;
}
.bg-palegreen-progress-bar{
  background-color: #98FB98 !important;
}
.bg-honeydew-progress-bar{
  background-color: #F0FFF0 !important;
}
.bg-sea-green-progress-bar{
  background-color: #2E8B57 !important;
}
.bg-medium-sea-green-progress-bar{
  background-color: #3CB371 !important;
}
.bg-mintcream-progress-bar{
  background-color: #F5FFFA !important;
}
.bg-medium-aquamarine-progress-bar{
  background-color: #66CDAA !important;
}
.bg-aquamarine-progress-bar{
  background-color: #7FFFD4 !important;
}
.bg-dark-slate-gray-progress-bar{
  background-color: #2F4F4F !important;
}
.bg-pale-turquoise-progress-bar{
  background-color: #AFEEEE !important;
}
.bg-light-cyan-progress-bar{
  background-color: #E0FFFF !important;
}
.bg-azure-progress-bar{
  background-color: #F0FFFF !important;
}
.bg-cadet-blue-progress-bar{
  background-color: #5F9EA0 !important;
}
.bg-powder-blue-progress-bar{
  background-color: #B0E0E6 !important;
}
.bg-light-blue-progress-bar{
  background-color: #ADD8E6 !important;
}
.bg-sky-blue-progress-bar{
  background-color: #87CEEB !important;
}
.bg-lightsky-blue-progress-bar{
  background-color: #87CEFA !important;
}
.bg-steel-blue-progress-bar{
  background-color: #4682B4 !important;
}
.bg-alice-blue-progress-bar{
  background-color: #F0F8FF !important;
}
.bg-slate-gray-progress-bar{
  background-color: #708090 !important;
}
.bg-light-slate-gray-progress-bar{
  background-color: #778899 !important;
}
.bg-lightsteel-blue-progress-bar{
  background-color: #B0C4DE !important;
}
.bg-cornflower-blue-progress-bar{
  background-color: #6495ED !important;
}
.bg-lavender-progress-bar{
  background-color: #E6E6FA !important;
}
.bg-ghost-white-progress-bar{
  background-color: #F8F8FF !important;
}
.bg-midnight-blue-progress-bar{
  background-color: #191970 !important;
}
.bg-slate-blue-progress-bar{
  background-color: #6A5ACD !important;
}
.bg-dark-slate-blue-progress-bar{
  background-color: #483D8B !important;
}
.bg-medium-slate-blue-progress-bar{
  background-color: #7B68EE !important;
}
.bg-medium-purple-progress-bar{
  background-color: #9370DB !important;
}
.bg-indigo-progress-bar{
  background-color: #4B0082 !important;
}
.bg-medium-orchid-progress-bar{
  background-color: #BA55D3 !important;
}
.bg-plum-progress-bar{
  background-color: #DDA0DD !important;
}
.bg-violet-progress-bar{
  background-color: #EE82EE !important;
}
.bg-thistle-progress-bar{
  background-color: #D8BFD8 !important;
}
.bg-orchid-progress-bar{
  background-color: #DA70D6 !important;
}
.bg-lavenderb-lush-progress-bar{
  background-color: #FFF0F5 !important;
}
.bg-pale-violet-red-progress-bar{
  background-color: #DB7093 !important;
}
.bg-pink-progress-bar{
  background-color: #FFC0CB !important;
}
.bg-lightpink-progress-bar{
  background-color: #FFB6C1 !important;
}
.bg-black-progress-bar{
  background-color: #000000 !important;
}
.bg-dimgray-progress-bar{
  background-color: #696969 !important;
}
.bg-gray-progress-bar{
  background-color: #808080 !important;
}
.bg-dark-gray-progress-bar{
  background-color: #A9A9A9 !important;
}
.bg-silver-progress-bar{
  background-color: #C0C0C0 !important;
}
.bg-light-grey-progress-bar{
  background-color: #D3D3D3 !important;
}
.bg-gainsboro-progress-bar{
  background-color: #DCDCDC !important;
}
.bg-white-smoke-progress-bar{
  background-color: #F5F5F5 !important;
}
.bg-white-progress-bar{
  background-color: #FFFFFF !important;
}