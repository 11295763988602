// font initialization
@font-face {
  font-family: "Roboto";
  src: local("AssistantRegular"),
    url("../fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Italic";
  src: local("AssistantRegular"),
    url("../fonts/Roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("AssistantRegular"),
    url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("AssistantRegular"),
    url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
}
